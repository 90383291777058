<template>
	<div>
		<Loading v-if="isLoading"/>
		<div v-else class="wrap" style="min-height: 100vh;background: #F8F8F8;overflow: hidden;"> 
			<div>
				<div class='blockTop'>
					<div class='lineLeft'>
						<img src='@/assets/img/defaultHead.png' class='headImg'/>
						<div class='customerInfo'>
							<div class='customerText'>{{personName}}</div>
							<div class='customerText'>{{mackMobile(personMobile)}}</div>
							<div class='customerText'>自然人客户</div>
						</div>
					</div>
					<div class='lineRight' @click='bindPublic'>
						<img src='@/assets/img/exchange.png' class='changeImg'/>
						<div class='btnText'>切换至企业</div>
					</div>
				</div>
				<div class="blockOne"> 
					<span class="header">查询</span>
					<van-grid :column-num="4" :border="false">
						<van-grid-item 
							v-for="(item,index) in listQuery" 
							:key="index" 
							:icon="item.icon" 
							:text="item.text"
							@click="handdleNext(item.flag,item.text,item.code)"
						/>
					</van-grid>
				</div>
				<div class="blockOne"> 
					<span class="header">申请</span>
					<van-grid :column-num="4" :border="false">
						<van-grid-item 
							v-for="(item,index) in listApply" 
							:key="index" :icon="item.icon" 
							:text="item.text"
							:to='item.urlTo'
							@click="handdleNext(item.flag,item.text,item.code)"
						/>
					</van-grid>
				</div>
				<template>
					<van-dialog
						v-model="showDialog"
						show-cancel-button
						@cancel="onCancel"
						@confirm='onConfirm'
						confirmButtonColor='#333333'
						cancelButtonColor='#999999'
					>
						<div class='dialogCenter'>
							{{personName}}暂未绑定<span style='color: #E50012'>企业</span>，是否需要进行绑定
						</div>
					</van-dialog>
				</template>
			</div>
		</div>
	</div>
	</template>
	<script>
		import {queryContract,rentPlanContract,calculationDetail,settleApplicationDetail,
		corporateRepaymentDetail,modifyRepayment,modifyInfo,modifyCardInfo,getImgToken,
		getOpenId,coninquiry,querytype,getWxMenuCue, getShare} from '@/server'
		import { getpublicbindstatus } from '@/api/enterprise'
		import { Toast,Dialog,Grid, GridItem,Overlay } from 'vant';
		import { setCookie, getCookie, domainUrl, hidewxOption } from '@/lib/utils';
		import Loading from "@/components/Loading";
		// window.location.reload() 
		export default {
			components: {
				Loading
			},
			name:'personalCenter',
			data() {
				return{
					cardId:this.$store.state.cardId,
					openId:getCookie('openId'),
					code:this.$route.query.code || '',
					accessToken:this.$store.state.accessToken,
					bindStatus:localStorage.getItem("bindStatus"),
					isLoading: true,
					listQuery:[{
						code:'menu_001',
						text:'订单查询',
						flag:'orderQuery',
						icon:require('@/assets/img/icon/orderQuery.png')
					},{
						code:'menu_002',
						text:'合同查询',
						flag:'contractQuery',
						icon:require('@/assets/img/icon/contractQuery.png')
					},{
						code:'menu_003',
						text:'租金计划',
						flag:'rentPlan',
						icon:require('@/assets/img/icon/rentPlan.png')
					},{
						code:'menu_004',
						text:'我的申请',
						flag:'myApply',
						icon:require('@/assets/img/icon/myApply.png')
					},{
						code:'menu_005',
						text:'扣款卡查询',
						flag:'cardInfo',
						icon:require('@/assets/img/icon/cardInfo.png')
					},{
						code:'menu_006',
						text:'结清查询',
						flag:'settlementQuery',
						icon:require('@/assets/img/icon/settlementQuery.png')
					},{
						code:'menu_007',
						text:'结清资料邮寄',
						flag:'settleInfoMail',
						icon:require('@/assets/img/icon/settleInfoMail.png')
					}],
					listApply:[{
						code:'menu_008',
						text:'提前结清申请',
						flag:'earlyApart',
						icon:require('@/assets/img/icon/earlyApart.png')
					},
					{
						code:'menu_009',
						text:'自助还款',
						flag:'selfRepayment',
						icon:require('@/assets/img/icon/selfRepayment.png')
					},
					{
						code:'menu_010',
						text:'对公还款',
						flag:'coorRe',
						icon:require('@/assets/img/icon/coorRe.png')
					},{
						code:'menu_011',
						text:'扣款卡变更',
						flag:'cardModify',
						icon:require('@/assets/img/icon/cardModify.png')
					},{
						code:'menu_012',
						text:'还款日变更',
						flag:'dateModify',
						icon:require('@/assets/img/icon/dateModify.png')
					},{
						code:'menu_013',
						text:'手机号变更',
						flag:'infoModify',
						icon:require('@/assets/img/icon/infoModify.png')
					},{
            code:'menu_016',
            text:'前置保证金',
            flag:'advanceDeposit',
            icon:require('@/assets/img/icon/advanceDeposit.png')
          },{
						code:'menu_014',
						text:'个人信息',
						flag:'personInfo',
						icon:require('@/assets/img/icon/personInfo.png')
					}],
					personName: '',
					personMobile: '',
					showDialog: false
				}
			},
			mounted(){
        sessionStorage.removeItem("origin");
        sessionStorage.removeItem("source");
        sessionStorage.removeItem("payInfoId");
				sessionStorage.setItem('key', 1);
				window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
					if(sessionStorage.getItem('key') == 1) {
						document.addEventListener('WeixinJSBridgeReady', function(){ WeixinJSBridge.call('closeWindow'); }, false); 
						WeixinJSBridge.call('closeWindow');
					}
				}, false);
			},
			async created(){
				// if(location.href.indexOf("#reloaded")==-1){
				// 	location.href = location.href + "#reloaded";
				// 	location.reload()
					// let url = window.location.href
					// url = url.split('#')
					// window.location.replace(url[0]+ "#reloaded")
				// }
				this.$store.commit('dataListTotal', {});
				this.$store.commit('imgUrlList', []);
				this.$store.commit('backFlag', '');
				await this.getToken();
				this.getImgToken();
				let userAgent = navigator.userAgent;
				if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
					sessionStorage.setItem("originUrl", window.location.href); // 用于ios分享
				}
				//隐藏分享
				// hidewxOption()
			},
			methods:{
				onCancel(){
					this.showDialog = false
				},
				mackMobile(val){
					if(!val){
						return ''
					}
					return val.replace(/(\d{3})\d{4}(\d{4})/,'$1****$2')
				},
				bindPublic(){
					getpublicbindstatus({
						openid: this.openId
					}).then((res)=>{
						if(res.wx_code == '0'){
							if(res.com_bind_status == '1'){
								Toast({
									message: '切换成功',
									icon: require('@/assets/img/bindSuccess.png'),
									duration:'2000'
								});
								// setTimeout(()=>{
									// this.$store.commit('setPublicData', res)
									sessionStorage.removeItem('key')
									this.$router.push({ path: '/enterprise/enterprisePersonalCenter'})
								// },1000)
							}else{
								this.showDialog = true
							}
						}
					})
				},
				onConfirm(){
					sessionStorage.removeItem('key')
					this.$router.push({ path: '/enterprise/enterpriseBind', query: { cardId: this.cardId, telNum: this.personMobile}})
				},
				getImgToken(){
					getImgToken({openid:this.openId,imgpath:'menu'}).then(res=>{
						if(res.wx_code=='0'){
							localStorage.setItem("token", res.img_token);
						}
					})
				},
				getBindStatus(Xindex,Xtext,Xcode){
					// console.log(this.bindStatus)
					const params = {
							data:{
								code:Xcode
							}
						}
						getWxMenuCue(params).then(res=>{
							Toast.clear();
							if(res.status == '200'){
								if(res.data.isOpen=='0'){
									Dialog.alert({
										message: res.data.cue
									});
								}else{
									if(this.bindStatus !=='1'){
										this.$router.push({ path: '/login?redirect=/personalCenter' });
									}else{
										this.nextTo(Xindex,Xtext,Xcode)
									}
								}
							}else{
								Toast.fail({
									message: res.msg,
									duration:'3000'
								});
							}
						})
				},
				getToken(){
					// alert(this.openId)
					// localStorage.setItem("token", '');
					// 'ofulr1WcE048SyjXf6EfvVFVYqs'
					//如果有openid
					return new Promise((resolve,reject)=>{
						if(this.openId){
							getpublicbindstatus({openid:this.openId}).then(res=>{
								if(res.wx_code=='0'){
									this.accessToken = res.access_token;
									localStorage.setItem("accessToken", res.access_token);
									this.$store.commit('accessToken', res.access_token);
									setCookie('openId',res.openid);
									this.openId = res.openid;
									this.$store.commit('openId', res.openid);
									resolve()
									if(res.bind_status == 1){
										this.isLoading = false
										this.$store.commit('bindStatus', res.bind_status);
										localStorage.setItem("bindStatus", res.bind_status);
										this.$store.commit('cardId', res.card_id);
										localStorage.setItem("cardId", res.card_id);
										this.cardId = res.card_id;
										this.bindStatus = res.bind_status;
										this.personName = res.cus_name
										this.personMobile = res.cus_mobile
									}else if(res.com_bind_status == 1){//企业
										// this.$store.commit('setPublicData', res)
										sessionStorage.removeItem('key')
										this.$router.push({ path: '/enterprise/enterprisePersonalCenter' });
									}else{//都没绑定跳转登录页绑定
										this.$router.push({ path: '/login?redirect=/personalCenter' });
									}
									
								}else{
									this.isLoading = false;
										Toast.fail({
											message: res.msg,
											duration:'3000'
										});
									}
							})
							// if(window.location.href.indexOf("#reloaded")==-1){
							// 	window.location.href = location.href + "#reloaded";
							// 	window.location.reload()
							// }
						} else{
							if(!this.code) {
								// alert(this.openId)
								this.$store.commit('bindStatus', '1');
								localStorage.setItem("bindStatus", '1');
								let uiCode = encodeURIComponent(domainUrl+'/personalCenter');
								window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
								Toast.clear();
							}else{//获取code
								getOpenId({percode:this.code}).then(response=>{
									if(response.wx_code=='0'){
										getpublicbindstatus({openid: response.openid}).then(res=>{
											if(res.wx_code=='0'){
												this.accessToken = res.access_token;
												localStorage.setItem("accessToken", res.access_token);
												this.$store.commit('accessToken', res.access_token);
												setCookie('openId',res.openid);
												this.openId = res.openid;
												this.$store.commit('openId', res.openid);
												resolve()
												if(res.bind_status == 1){
													this.isLoading = false
													this.$store.commit('bindStatus', res.bind_status);
													localStorage.setItem("bindStatus", res.bind_status);
													this.$store.commit('cardId', res.card_id);
													localStorage.setItem("cardId", res.card_id);
													this.cardId = res.card_id;
													this.bindStatus = res.bind_status;
													this.personName = res.cus_name
													this.personMobile = res.cus_mobile
													resolve()
												}else if(res.com_bind_status == 1){//企业
													setCookie('openId',res.openid);
													this.openId = res.openid;
													this.$store.commit('openId', res.openid);
													resolve()
													// this.$store.commit('publicAccessToken', res.com_access_token);
													sessionStorage.removeItem('key')
													this.$router.push({ path: '/enterprise/enterprisePersonalCenter' });
												}else{//都没绑定跳转登录页绑定
													this.$router.push({ path: '/login?redirect=/personalCenter' });
												}
												
											}
										})
									}else{
										this.isLoading = false;
										Toast.fail({
											message: res.msg,
											duration:'3000'
										});
									}
								});
							}
						}
					})
					
				},
				handdleNext(Xindex,Xtext,Xcode){
					Toast.loading({
						duration: 0, // 持续展示 toast
						forbidClick: true,
						message: "加载中"
					});
					this.getBindStatus(Xindex,Xtext,Xcode);
				},
				nextTo(Xindex,Xtext,Xcode){
					//公众号查询统计
					sessionStorage.setItem('menu_name',Xtext)
					const querytypeparams = {
						card_id:this.cardId,
						function_type:'menu',
						menu_name:Xtext
					}
					querytype(querytypeparams);
					if(Xindex=='orderQuery'){
						//订单查询
						Toast.clear();
						this.$router.push({ path: '/applyQuery' });
					}else if(Xindex=='contractQuery'){
						//合同查询
						sessionStorage.removeItem('key')
						const params = {
							access_token:this.accessToken,
							card_id:this.cardId
						}
						queryContract(params).then(res=>{
							Toast.clear();
							if(res.status == '200'){
								this.$store.commit('dataListTotal', res.data);
								this.$router.push({ path: '/contractQuery'});
							}else{
								Toast.fail({
									message: res.msg,
									duration:'3000'
								});
							}
						})
					}else if(Xindex=='rentPlan'){
						//租金计划
						const params = {
							access_token:this.accessToken,
							card_id:this.cardId
						}
						rentPlanContract(params).then(res=>{
							Toast.clear();
							if(res.status=='200'){
								// this.list = res.data;
								this.$store.commit('dataListTotal', res.data);
								this.$router.push({ path: '/rentPlanCtr' });
							}else{
								Toast.fail({
									message: res.msg,
									duration:'3000'
								});
							}
						})
					}else if(Xindex=='myApply'){
						//我的申请
						Toast.clear();
						sessionStorage.removeItem('key')
						this.$router.push({ path: '/application?flag=1' });
					}else if(Xindex=='cardInfo'){
						//扣款卡查询
						Toast.clear();
						const params = {
							access_token:this.accessToken,
							card_id:this.cardId
						}
						coninquiry(params).then(res=>{
							if(res.status=='200'){
								this.$store.commit('dataListTotal', res.data);
								this.$router.push({ path: '/cardQuery' });
							}else{
								Toast.fail({
									message: res.msg,
									duration:'3000'
								});
							}
						})
					}else if(Xindex=='settlementQuery'){
						//结清查询
						Toast.clear();
						const params = {
							access_token:this.accessToken,
							card_id:this.cardId,
							query_type:'close'
						}
						queryContract(params).then(res=>{
							if(res.status == '200'){
								// if(res.cus_type=='z'){
								// 	Toast.fail({
								// 		message: '此功能暂未开通',
								// 		duration:'3000'
								// 	});
								// }else{
									this.$store.commit('dataListTotal', res.data);
									this.$router.push({ path: '/settlementQuery'});
								// }
							}else if(res.status == '502'){
								Toast.fail({
									message: '您好，您名下暂无结清合同',
									duration:'3000'
								});
							}else{
								Toast.fail({
									message: res.msg,
									duration:'3000'
								});
							}
						})
						// const params = {
						// 	access_token:this.accessToken,
						// 	card_id:this.cardId
						// }
						// queryContract(params).then(res=>{
						// 	Toast.clear();
						// 	if(res.status == '200'){
						// 		this.$store.commit('dataListTotal', res.data);
						// 		this.$router.push({ path: '/settlementQuery'});
						// 		if(res.cus_type !=='z'){
						// 			this.$store.commit('dataListTotal', res.data);
						// 			this.$router.push({ path: '/settlementQuery'});
						// 		}else{
						// 			Toast.fail({
						// 				message: '没有可用合同',
						// 				duration:'3000'
						// 			});
						// 		}
						// 	}else{
						// 		Toast.fail({
						// 			message: res.msg,
						// 			duration:'3000'
						// 		});
						// 	}
						// })
					
					}else if(Xindex=='settleInfoMail'){//结清资料邮寄
						Toast.clear();
						const params = {
							access_token:this.accessToken,
							card_id:this.cardId,
							query_type:'close'
						}
						queryContract(params).then(res=>{
							if(res.status == '200'){
								if(res.cus_type=='z'){
									Dialog.alert({
										message: '您好，您的结清资料在您提供邮寄地址后7个工作日内寄出，您可以拨打4000011255进行咨询！'
									});
									// Toast.fail({
									// 	// message: '您的资料正在准备中，详情请咨询客服',
									// 	message:'您好，您的结清资料在您提供邮寄地址后7个工作日内寄出，您可以拨打4000011255进行咨询！',
									// 	duration:'3000'
									// });
								}else{
									sessionStorage.removeItem('key')
									this.$store.commit('dataListTotal', res.data);
									this.$router.push({ path: '/settleInfoMail?flag=1'});
								}
							}else if(res.status == '502'){
								Toast.fail({
									message: '您好，您名下暂无结清合同',
									duration:'3000'
								});
							}else{
								Toast.fail({
									message: res.msg,
									duration:'3000'
								});
							}
						})
					}else if(Xindex=='earlyApart'){
						//提前结清测算
						const params = {
							access_token:this.accessToken,
							card_id:this.cardId
						}
						calculationDetail(params).then(res=>{
							Toast.clear();
							if(res.status == '0'){
								this.$store.commit('dataListTotalCal', res.detail_data);
								this.$router.push({ path: '/earlySettlementCalculation' });
							}else{
								Toast.fail({
									message: res.msgall,
									duration:'3000'
								});
							}	
						})
					}else if(Xindex=='earlyApply'){
						//提前结清申请
						const params = {
							access_token:this.accessToken,
							card_id:this.cardId
						}
						settleApplicationDetail(params).then(res=>{
							Toast.clear();
							if(res.status == '0'){
								this.$store.commit('dataListTotal', res.detail_data);
							this.$router.push({ path: '/earlySettlementApplication' });
							}else{
								Toast.fail({
									message: res.msgall,
									duration:'3000'
								});
							}
						})
					}else if(Xindex=='coorRe'){
						//对公还款
						const params = {
							access_token:this.accessToken,
							card_id:this.cardId
						}
						corporateRepaymentDetail(params).then(res=>{
							Toast.clear();
							if(res.status == '0'){
								this.$store.commit('dataListTotal', res.detail_data);
								this.$router.push({ path: '/corporateRepayment' });
							}else{
								Toast.fail({
									message: res.msgall,
									duration:'3000'
								});
							}
						})
					}else if(Xindex=='cardModify'){
						//扣款卡变更
						const params = {
							access_token:this.accessToken,
							card_id:this.cardId
						}
						modifyCardInfo(params).then(res=>{
							Toast.clear();
							if(res.status=='200'){
								this.$store.commit('dataListTotal', res.data);
								this.$router.push({ path: '/modifyDebitCard' });
							}else{
								Toast.fail({
									message: res.msg,
									duration:'3000'
								});
							}
						})
					}else if(Xindex=='dateModify'){
						//还款日变更
						const params = {
							access_token:this.accessToken,
							card_id:this.cardId
						}
						modifyRepayment(params).then(res=>{
							Toast.clear();
							if(res.status=='200'){
								this.$store.commit('dataListTotal', res.data);
								this.$router.push({ path: '/modifyRepayment' });
							}else{
								Toast.fail({
									message: res.msg,
									duration:'3000'
								});
							}
						})
					}else if(Xindex=='infoModify'){
						//手机号变更
						const params = {
							access_token:this.accessToken,
							card_id:this.cardId
						}
						modifyInfo(params).then(res=>{
							Toast.clear();
							if(res.status=='200'){
								this.$store.commit('dataListTotal', res.data);
								this.$router.push({ path: '/modifyInfo' });
							}else{
								Toast.fail({
									message: res.msg,
									duration:'3000'
								});
							}
						})
					}else if(Xindex=='advanceDeposit'){
            Toast.clear();
            //前置保证金
            sessionStorage.removeItem('key')
            this.$router.push({ path: '/advanceDeposit'});
          }else if(Xindex=='personInfo'){
						Toast.clear();
						//个人信息
						this.$router.push({ path: '/Account' });
					}else if(Xindex=='selfRepayment'){
						Toast.clear();
						//自助还款
						const params = {
							access_token:this.accessToken,
							card_id:this.cardId,
							query_type:'noclose'
						}
						queryContract(params).then(res=>{
							if(res.status == '200'){
								if(res.cus_type=='z'){
									Toast.fail({
										message: '此功能未开通',
										duration:'3000'
									});
								}else{
									this.$store.commit('dataListTotal', res.data);
									this.$router.push({ path: '/selfRepayment'});
								}
							}else{
								Toast.fail({
									message: res.msg,
									duration:'3000'
								});
							}	
						})
					}
				},
				submit(){}
			}
		}
	</script>
	<style scoped>
	.blockTop{
		background-color: #FFFFFF;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 7px 20px 0px;
	}
	.lineLeft{
		display: flex;
		flex-direction: row;
		align-items: flex-start;
	}
	.headImg{
		width: 32px;
		height: 32px;
		margin-right: 10px;
	}
	.customerText{
		color: #333333;
		font-size: 12px;
		line-height: 12px;
		margin-bottom: 8px;
	}
	.lineRight{
		width: 106px;
		height: 27px;
		border: 1px solid #0256FF;
		border-radius: 27px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.changeImg{
		width: 16px;
		height: 16px;
	}
	.btnText{
		color: #0256FF;
		font-size: 14px;
	}
	.blockOne{
		background-color: #fff;
		margin-top: 10px;
	}
	.blockOne .header{
		margin: 10px;
		display: inline-block;
		font-size: 15px;
	}
	.dialogCenter{
		padding: 30px;
		text-align: center;
		line-height: 30px;
	}
	</style>